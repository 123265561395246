import React, { useEffect, useRef, useState } from 'react';
import Footer from "../../components/footer";
import Header from "../../components/header";
import LanguageIcon from '@mui/icons-material/Language';
import { Container, Box, Typography, setRef, Grid } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import BoxTrans from "../../components/boxTrans";
import BoxTextToSpeech from "../../components/boxTextToSpeech";
import { List, ListItem, ListItemText, Divider, Button } from '@mui/material';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
const options = [{ name: "Translate", title: "20 languages" }, { name: "Text to speech", title: "Speech Natural" }];
const FreeOption = () => {
    return (
      <List>
        <ListItem>
          <ListItemText primary="Tốc độ" secondary="giới hạn 10 request mỗi phút" />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Số lượng request dịch" secondary="10 request mỗi phút" />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Số từ dịch" secondary="giới hạn 5000 từ" />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Dịch hình ảnh" secondary="giới hạn 1 hình mỗi phút, hình ảnh dưới 2mb" />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Dịch tài liệu" secondary="giới hạn 1 tập tin mỗi phút" />
        </ListItem>
      </List>
    );
  };
  const VipOption = () => {
    return (
        <List>
          <ListItem>
            <ListItemText primary="Tốc độ" secondary="cực cao" />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Số lượng request dịch" secondary="không giới hạn" />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Số từ dịch" secondary="không giới hạn" />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Dịch hình ảnh" secondary="không giới hạn" />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Dịch tài liệu" secondary="không giới hạn" />
          </ListItem>
        </List>
      );
  };
const ButtonCustom = styled(Paper)(({ theme, index, indexChoose }) => ({
    backgroundColor: "#ffffff",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    borderBottom: index === 0 ? "3px solid black" : "none",
    '&:hover': {
        backgroundColor: indexChoose === index ? "$ffffff" : '#f5f7f8', // Background color on hover
    },
    cursor: "pointer",
    color: index === 0 ? "black" : "#0192cc",
    marginRight: "12px",
    maxHeight: "30px",
    userSelect: "none"
}));
export default function HomePage() {
    const buttonBoxRef = useRef([]);
    const containerRef = useRef(null);
    const [size, setSize] = useState({ width: 0, height: 0 });
    const [chooseOption, setChooseOption] = useState(0);
    const handleCheckOption = (index) => {
        setChooseOption(index);
        if (buttonBoxRef.current.length > 0) {
            // Thay đổi màu nền của phần tử đầu tiên
            options.map((e, indexE) => {
                if (index === indexE) {
                    buttonBoxRef.current[index].style.borderBottom = "3px solid black";
                    buttonBoxRef.current[index].style.color = "black";
                } else {
                    buttonBoxRef.current[indexE].style.borderBottom = "none";
                    buttonBoxRef.current[indexE].style.color = "#0192cc";
                }
            })
        }
    }
    const setButtonBoxRef = (ref, index) => {
        buttonBoxRef.current[index] = ref;
    }
    useEffect(() => {
        const handleResize = () => {
          if (containerRef.current) {
            setSize({
              width: containerRef.current.offsetWidth,
              height: containerRef.current.offsetHeight,
            });
          }
        };
    
        const resizeObserver = new ResizeObserver(handleResize);
    
        if (containerRef.current) {
          resizeObserver.observe(containerRef.current);
        }
    
        // Clean up observer on component unmount
        return () => {
          if (containerRef.current) {
            resizeObserver.unobserve(containerRef.current);
          }
          resizeObserver.disconnect();
        };
      }, []);
    return (
        <>
            <Box ref={containerRef} sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                backgroundColor: "#f5f7f8"
            }}>
                <Header />
                <Container maxWidth="lg" component="body" sx={{ flex: 1 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: 8,
                        }}
                    >
                        <Box component="div" sx={{ display: "flex", width: "100%", marginLeft: "0px" }}>
                            {
                                options.map((e, index) => (
                                    <ButtonCustom key={index} index={index} indexChoose={chooseOption} ref={(el) => setButtonBoxRef(el, index)} onClick={() => handleCheckOption(index)}>
                                        {e.name === 'Translate File' ? (<PictureAsPdfIcon sx={{ fontSize: '35px', marginRight: "10px" }} />) :
                                            e.name === 'Text to speech' ? (<VoiceChatIcon sx={{ fontSize: '35px', marginRight: "10px" }} />) : (<LanguageIcon sx={{ fontSize: '35px', marginRight: "10px" }} />)}
                                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                                            <Typography component="name_options" gutterBottom sx={{ marginBottom: "0px" , fontSize: { xs: "12px", sm: "14px", md: "16px" }}}>{e.name} </Typography>
                                            <Typography variant="inherit" sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }}} component="name_options" gutterBottom>{e.title}</Typography>
                                        </Box>

                                    </ButtonCustom>
                                ))
                            }
                        </Box>
                        {
                            chooseOption === 0 ? (<BoxTrans />) : (<BoxTextToSpeech />)
                        }
                        <Typography id="introduce" variant="h2" component="h1" gutterBottom sx={{ marginTop: "50px" }}>
                            Welcome to TransL
                        </Typography>
                        <Typography variant="body1" align="center" sx={{marginBottom:"20px"}}>
                        This website leverages the power of AI machine learning to help you solve problems such as translation, image translation, and file translation. 
                            <br></br> Come to us for a great experience, and you can use it for free within our limits. 
                        </Typography>
                        {/* <Box component={"div"} sx={{ width: "100%", marginTop:'30px', marginBottom:"30px" }}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} sx={{display:"block", borderRight:"2px solid black",justifyContent:"center", textAlign:"center", alignContent:"top"}}>
                                    <Typography variant="h4" component="h1" gutterBottom sx={{ marginTop: "50px" }}>
                                        Gói người dùng miễn phí
                                    </Typography>
                                    <FreeOption />
                                    <Button variant="contained" color="success" sx={{marginTop:"20px"}}>
                                        Đăng nhập để dùng miễn phí
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sx={{display:"block", justifyContent:"center", textAlign:"center", alignContent:"top"}}>
                                    <Typography variant="h4" component="h1" gutterBottom sx={{ marginTop: "50px" }}>
                                        Gói người dùng tính phí
                                    </Typography>
                                    <VipOption />
                                    <Button variant="contained" color="success" sx={{marginTop:"20px"}}>
                                        Đăng kí gói để dùng không giới hạn
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box> */}

                        {/* Thêm nội dung chính ở đây */}
                    </Box>
                </Container>
                <Footer />
            </Box>

        </>

    );
};

