import * as React from 'react';
import { Box, Grid, Typography, TextField } from '@mui/material';
import { useRef } from 'react';
import ButtonShow from '../buttonShow/buttonShow';
import IconSwitch from '../../public/change.png'
import useOutsideClick from '../../utils/useClickOutSide';
import translateService from '../../services/translateService';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import ControlsAudio from '../controlsAudio';
import dataSpeech from '../../data/speachCode.json';
const listLan = [
    {
        name: "Arabic",
        code: "ar",
        code_speech: 'ar-XA', // Sử dụng mã ngôn ngữ của Google Cloud
    },
    {
        name: "Chinese (Simplified)",
        code: "zh",
        code_speech: 'zh-CN', // Simplified Chinese
    },
    {
        name: "Chinese (Traditional)",
        code: "zh-Hant",
        code_speech: 'zh-TW', // Traditional Chinese
    },
    {
        name: "Czech",
        code: "cs",
        code_speech: 'cs-CZ',
    },
    {
        name: "Dutch",
        code: "nl",
        code_speech: 'nl-NL',
    },
    {
        name: "English",
        code: "en",
        code_speech: 'en-US', // Có thể tùy chọn cho nhiều loại English khác nhau như en-GB, en-AU, v.v.
    },
    {
        name: "French",
        code: "fr",
        code_speech: 'fr-FR',
    },
    {
        name: "German",
        code: "de",
        code_speech: 'de-DE',
    },
    {
        name: "Indonesian",
        code: "id",
        code_speech: 'id-ID',
    },
    {
        name: "Italian",
        code: "it",
        code_speech: 'it-IT',
    },
    {
        name: "Japanese",
        code: "ja",
        code_speech: 'ja-JP',
    },
    {
        name: "Korean",
        code: "ko",
        code_speech: 'ko-KR',
    },
    {
        name: "Polish",
        code: "pl",
        code_speech: 'pl-PL',
    },
    {
        name: "Portuguese",
        code: "pt",
        code_speech: 'pt-BR', // Có thể là pt-PT cho Bồ Đào Nha
    },
    {
        name: "Romanian",
        code: "ro",
        code_speech: 'ro-RO',
    },
    {
        name: "Russian",
        code: "ru",
        code_speech: 'ru-RU',
    },
    {
        name: "Spanish",
        code: "es",
        code_speech: 'es-ES', // Có thể là es-MX cho Mexico
    },
    {
        name: "Swedish",
        code: "sv",
        code_speech: 'sv-SE',
    },
    {
        name: "Turkish",
        code: "tr",
        code_speech: 'tr-TR',
    },
    {
        name: "Vietnamese",
        code: "vi",
        code_speech: 'vi-VN',
    }
];
function ShowLanguage({ listLans, refBox, chooseLanguage }) {
    const styleText = { display: "flex", userSelect: "none", height: "50px", cursor: "pointer", alignItems: "center", textAlign: "center", justifyContent: "center", alignContent: "center", '&:hover': { backgroundColor: "#f5f7f8" } };
    return (
        <Box component={'div'} ref={refBox} sx={{ padding: "10px", position: "absolute", zIndex: "1", maxWidth: "300px", backgroundColor: "white", boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);" }}>
            <Grid container spacing={0}>
                {listLans.map((e, index) => (
                    <Grid item xs={4}>
                        <Box onClick={() => chooseLanguage(e.code)}>
                            <Typography key={index} variant='caption' sx={styleText} >
                                {e.name} <br /> {e.code === 'zh' ? '(Simplified)' : e.code === 'zh-Hant' ? '(Traditional)' : ''}
                            </Typography>
                        </Box>

                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
function ShowSpeech({ listLans, refBox, chooseLanguage }) {
    const styleText = { display: "flex", userSelect: "none", height: "50px", cursor: "pointer", alignItems: "center", textAlign: "center", justifyContent: "center", alignContent: "center", '&:hover': { backgroundColor: "#f5f7f8" } };
    return (
        <Box component={'div'} ref={refBox} sx={{ padding: "10px", position: "absolute", zIndex: "1", maxWidth: "300px", backgroundColor: "white", boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);" }}>
            <Grid container spacing={0}>
                {listLans.map((e, index) => (
                    <Grid item xs={4}>
                        <Box onClick={() => chooseLanguage(e.speech)}>
                            <Typography key={index} variant='caption' sx={styleText} >
                                {e.speech} 
                            </Typography>
                        </Box>

                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
export default function BoxTextToSpeech() {
    const [valueTextDetect, setTextDetect] = React.useState('');
    const [hasAudio, setHasAudio] = React.useState(null);
    const [speechOption, setSpeechOption] = React.useState(null);
    const [speechOptions, setSpeechOptions] = React.useState(null);
    const [currentSpeechOption, setCurrentSpeechOption] = React.useState(null);
    const [openDetect, setOpenDetect] = React.useState(false);
    const [openTargetLanguage, setTargetLanguage] = React.useState(false);
    const [openChooseSpeech, setOpenChooseSpeech] = React.useState(false);
    const [isRotated, setIsRotated] = React.useState(false);
    const [isClickedBox, setIsClickedBox] = React.useState(false);
    const [isLoadingButtonTrans, setIsLoadingButtonTrans] = React.useState(false);
    const [languageTo, setLanguageTo] = React.useState(listLan[5]);
    const [languageFrom, setLanguageFrom] = React.useState(listLan[6]);
    const refSwitch = useRef(null);
    const refOpenDetect = useRef(null);
    const refTargetLanguage = useRef(null);
    const refSpeechOption = useRef(null);
    const refTranslated = useRef(null);
    const refTranslate = useRef(null);
    // Gắn hook để xử lý click ra ngoài cho cả hai menu
    useOutsideClick(refOpenDetect, () => setOpenDetect(false));
    useOutsideClick(refTargetLanguage, () => setTargetLanguage(false));
    useOutsideClick(refSpeechOption, () => setOpenChooseSpeech(false));
    const handleClickLanguageTo = (code) => {
        let resultObj = null;
        listLan.map(e => {
            if (e.code === code) {
                resultObj = e;
            }
        })
        setLanguageTo(resultObj);
        setOpenDetect(false);
    }
    const handleClickSpeechOption = (code) => {
        let resultObj = null;
        speechOptions.map(e => {
            if (e.speech === code) {
                resultObj = e;
            }
        })
        setSpeechOption(resultObj.speech);
        setOpenChooseSpeech(false);
    }
    const handleClickedBox = () => {
        setIsClickedBox(true);
    }
    const handleClickedBoxBlur = () => {
        setIsClickedBox(false);
    }
    const handleChange = (event) => {
        setTextDetect(event.target.value);
    };

    const handleClickChange = () => {
        setOpenDetect(prev => !prev);
    };

    const handleClickChangeTarget = () => {
        setTargetLanguage(prev => !prev);
    };
    const handleClickChangeSpeech = () => {
        setOpenChooseSpeech(prev => !prev);
    };
    React.useEffect(()=>{
        const updateSpeech = () =>{
            const data = dataSpeech;
            setSpeechOption(data[languageTo.code_speech][0].speech);
            setSpeechOptions(data[languageTo.code_speech]);
        }
        updateSpeech();
    },[languageTo]);
 
    const handleSwitchLanguage = () => {
        if(languageTo.code === "dt"){
            return
        }
        setIsRotated(pre => !pre);
        if (refSwitch.current) {
            refSwitch.current.style.transform = isRotated ? "rotateY(0deg)" : "rotateY(180deg)";
            refSwitch.current.style.transition = "transform 0.3s ease-in-out";
        }
        const temp = languageTo;
        setLanguageTo(languageFrom);
        setLanguageFrom(temp);
    }
    const handleSpeech = async () => {
        setIsLoadingButtonTrans(true);
        if (!refTranslate.current) return;
        const content = refTranslate.current.value;
        console.log(content);
        try {
            const resultSpeech = await translateService.textToSpeech(languageTo.code_speech, content, speechOption);
            if (resultSpeech && resultSpeech.audioContent) {
                // Giả sử audioContent là ArrayBuffer
                const audioBlob = new Blob([resultSpeech.audioContent], { type: 'audio/wav' });
                const audioUrl = URL.createObjectURL(audioBlob);
                setHasAudio(audioUrl);
            } else {
                console.error('No audio content received');
                setHasAudio(null);
            }
        } catch (error) {
            console.error('Error fetching audio:', error);
            setHasAudio(null);
        }
        setIsLoadingButtonTrans(false);
    }
    return (
        <Box component="div" sx={{ flexGrow: 1, width: '100%', marginTop: '10px', borderRadius: '5px', minHeight: '400px', backgroundColor: '#ffffff', boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)' }}>
            <Grid container spacing={0}>
                <Grid item xs={3} sx={{ borderBottom: "1px solid #dcdfe4" }}>
                <ButtonShow title={languageTo.name} open={openDetect} funcClick={handleClickChange} />
                {openDetect && <ShowLanguage listLans={listLan.filter(e => e.name !== languageFrom.name)} refBox={refOpenDetect} chooseLanguage={handleClickLanguageTo} />}
                </Grid>
                <Grid item xs={3} sx={{ borderBottom: "1px solid #dcdfe4" }}>
                <ButtonShow title={speechOption} open={openChooseSpeech} funcClick={handleClickChangeSpeech} />
                {openChooseSpeech && <ShowSpeech listLans={speechOptions} refBox={refSpeechOption} chooseLanguage={handleClickSpeechOption} />}
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', borderBottom: "1px solid #dcdfe4" }}>
                </Grid>
                <Grid item xs={5} sx={{ borderBottom: "1px solid #dcdfe4" }}>
                </Grid>
                <Grid item xs={12} sx={{ border: isClickedBox ? "1px solid #0177a9" : "1px solid transparent", borderRadius: "5px" }}>
                    <TextField
                        label=""
                        variant="outlined"
                        inputRef={refTranslate}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none',  // Remove the border
                                },
                                '&:hover fieldset': {
                                    border: 'none',  // Remove border on hover
                                },
                                '&.Mui-focused fieldset': {
                                    border: 'none',  // Remove border when focused
                                },
                            },
                            // Remove underline for filled variant (if using filled variant)
                            '& .MuiInput-underline:before': {
                                borderBottom: 'none',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: 'none',
                            },
                        }}
                        placeholder="Type to translate. Please enter the text you need to translate. Maximum 5000 characters"
                        value={valueTextDetect}
                        onChange={handleChange}
                        onFocus={handleClickedBox}
                        onClick={handleClickedBox}
                        onBlur={handleClickedBoxBlur}
                        fullWidth
                        multiline      // Enables multiline input
                        rows={12}       // Number of rows to display
                        error={valueTextDetect.length > 5000} // Shows error if input length is greater than 5000
                        inputProps={{ maxLength: 5000 }} // Limits input length
                    />
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ display: "flex", marginLeft: "10px", color: "grey", userSelect: "none" }}>
                            <Typography sx={{ fontWeight: "bold" }}>{valueTextDetect.length}</Typography> / 5000 Character
                        </Typography>
                        <ControlsAudio audioSrc={hasAudio} />
                        <LoadingButton loading={isLoadingButtonTrans ? true : false} variant="contained" onClick={handleSpeech} color="success" sx={{ margin: "2px", fontWeight: "bold", padding: "10px 20px 10px 20px", borderRadius: "20px" }}>
                            Convert Audio
                        </LoadingButton>
                        
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
