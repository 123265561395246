import React from 'react';
import Box from '@mui/material/Box';
import { AppBar, Toolbar, Typography, Button, Container, Link } from '@mui/material';
import ImageLogo from "../../public/main-logo.png"
import { Height } from '@mui/icons-material';
import "./style.scss";
const pages = ['Home', 'About', 'Contact'];

const Header = () => {
    return (
        <AppBar position="static" sx={{display:"flex", backgroundColor: 'white', margin: 0, padding: 0, color:"black", justifyContent:"center"}}>
            <Toolbar sx={{ justifyContent: 'center', flexGrow: 1 }}>
            <Box
            component="img"
            src={ImageLogo}
            sx={{
                marginLeft: '10px',
                width: '70px',
            }}
            alt="Logo" 
        />
                {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {pages.map((page) => (
                        <a 
                            className={'option_header'}
                            key={page}
                            style={{ my: 2, color: 'black', display: 'block', marginLeft: '10px',textDecoration:"none"}}
                            href="#introduce"
                        >
                            {page}
                        </a>
                    ))}
                </Box> */}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
