import axios from 'axios';

const apiCLient = () =>{
    return axios.create({
        baseURL: 'https://transl.online', // Địa chỉ cơ sở của API của bạn
        headers: {
          'Content-Type': 'application/json',
        },
      });
}
export default apiCLient();