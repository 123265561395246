import apiCLient from "./api";
class translateService {
    async translatText(to, target, content) {
        try {
            const response = await apiCLient.post('/api/v1/translate', {
                to,
                target,
                content
            })
            return response.data;
        } catch (er) {
            return {
                error: er
            }
        }
    }
    async textToSpeech(language, text, ssmlGender) {
        try {
            const response = await apiCLient.get(`/api/v1/text-to-speech`, {
                params: {
                    language,
                    text,
                    ssmlGender
                },
                responseType: 'arraybuffer' // Chỉ định kiểu dữ liệu trả về là binary
            });
            return {
                audioContent: response.data
            };
        } catch (er) {
            // Trả về lỗi nếu có
            console.error('Error fetching text-to-speech:', er);
            return {
                error: er.message || 'An error occurred'
            };
        }
    }
 
}
export default new translateService();