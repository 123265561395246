import { useEffect, useState } from "react";
import translateService from "../services/translateService";
export default function useDebounce(value, delay = 500) {
    const [debounceValue, setDebounceValue] = useState(value);
    useEffect(() =>{
        const handler = setTimeout(async ()=>{
            setDebounceValue(value)
        },delay);
        return () => {
            clearTimeout(handler);
        }
    },[value, delay])
    return debounceValue;
}