import { memo } from "react";
import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
export default function NoPage(){
    return (
        <>
            
            <h1>Page dont Found!!</h1>
            
        </>

    );
};

 