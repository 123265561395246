import React, { useRef, useEffect, useState, useDebugValue } from 'react';
import { Box, Grid, Typography, TextField } from '@mui/material';
import ButtonShow from '../buttonShow/buttonShow';
import IconSwitch from '../../public/change.png'
import useOutsideClick from '../../utils/useClickOutSide';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import translateService from '../../services/translateService';
import Button from '@mui/material/Button';
import MicIcon from '@mui/icons-material/Mic';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { LoadingButton } from '@mui/lab';
import AnimListen from '../animListen';
import useDebounce from '../../hooks/useDebounce';
const listLan = [
    {
        name: "Detected Language",
        code: "dt",
        code_speech: '' // Không có mã ngôn ngữ cụ thể cho loại này
    },
    {
        name: "Arabic",
        code: "ar",
        code_speech: 'ar-XA', // Sử dụng mã ngôn ngữ của Google Cloud
    },
    {
        name: "Chinese (Simplified)",
        code: "zh",
        code_speech: 'zh-CN', // Simplified Chinese
    },
    {
        name: "Chinese (Traditional)",
        code: "zh-Hant",
        code_speech: 'zh-TW', // Traditional Chinese
    },
    {
        name: "Czech",
        code: "cs",
        code_speech: 'cs-CZ',
    },
    {
        name: "Dutch",
        code: "nl",
        code_speech: 'nl-NL',
    },
    {
        name: "English",
        code: "en",
        code_speech: 'en-US', // Có thể tùy chọn cho nhiều loại English khác nhau như en-GB, en-AU, v.v.
    },
    {
        name: "French",
        code: "fr",
        code_speech: 'fr-FR',
    },
    {
        name: "German",
        code: "de",
        code_speech: 'de-DE',
    },
    {
        name: "Indonesian",
        code: "id",
        code_speech: 'id-ID',
    },
    {
        name: "Italian",
        code: "it",
        code_speech: 'it-IT',
    },
    {
        name: "Japanese",
        code: "ja",
        code_speech: 'ja-JP',
    },
    {
        name: "Korean",
        code: "ko",
        code_speech: 'ko-KR',
    },
    {
        name: "Polish",
        code: "pl",
        code_speech: 'pl-PL',
    },
    {
        name: "Portuguese",
        code: "pt",
        code_speech: 'pt-BR', // Có thể là pt-PT cho Bồ Đào Nha
    },
    {
        name: "Romanian",
        code: "ro",
        code_speech: 'ro-RO',
    },
    {
        name: "Russian",
        code: "ru",
        code_speech: 'ru-RU',
    },
    {
        name: "Spanish",
        code: "es",
        code_speech: 'es-ES', // Có thể là es-MX cho Mexico
    },
    {
        name: "Swedish",
        code: "sv",
        code_speech: 'sv-SE',
    },
    {
        name: "Turkish",
        code: "tr",
        code_speech: 'tr-TR',
    },
    {
        name: "Vietnamese",
        code: "vi",
        code_speech: 'vi-VN',
    }
];


function ShowLanguage({ listLans, refBox, chooseLanguage }) {
    const styleText = { display: "flex", userSelect: "none", height: "50px", cursor: "pointer", alignItems: "center", textAlign: "center", justifyContent: "center", alignContent: "center", '&:hover': { backgroundColor: "#f5f7f8" } };
    return (
        <Box component={'div'} ref={refBox} sx={{ padding: "10px", position: "absolute", zIndex: "1", maxWidth: "300px", backgroundColor: "white", boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);" }}>
            <Grid container spacing={0}>
                {listLans.map((e, index) => (
                    <Grid item xs={4}>
                        <Box onClick={() => chooseLanguage(e.code)}>
                            <Typography key={index} variant='caption' sx={styleText} >
                                {e.name} <br /> {e.code === 'zh' ? '(Simplified)' : e.code === 'zh-Hant' ? '(Traditional)' : ''}
                            </Typography>
                        </Box>

                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
export default function BoxTrans() {
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [valueTextDetect, setTextDetect] = React.useState('');
    const [valueTextTarget, setTextTarget] = React.useState('');
    const textTranslate = useDebounce(valueTextDetect, 1000);
    const { transcript, browserSupportsSpeechRecognition, resetTranscript } = useSpeechRecognition();
    const [isSpeak, setIsSpeak] = useState('');
    const [isListenMic, setIsListenMic] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const [openDetect, setOpenDetect] = React.useState(false);
    const [openTargetLanguage, setTargetLanguage] = React.useState(false);
    const [isRotated, setIsRotated] = React.useState(false);
    const [isClickedBox, setIsClickedBox] = React.useState(false);
    const [isLoadingButtonTrans, setIsLoadingButtonTrans] = React.useState(false);
    const [languageTo, setLanguageTo] = React.useState(listLan[0]);
    const [languageFrom, setLanguageFrom] = React.useState(listLan[6]);
    const refSwitch = useRef(null);
    const refOpenDetect = useRef(null);
    const refTargetLanguage = useRef(null);
    const refTranslated = useRef(null);
    const refTranslate = useRef(null);
    const animationClickCopy = useRef();
    // Gắn hook để xử lý click ra ngoài cho cả hai menu
    useOutsideClick(refOpenDetect, () => setOpenDetect(false));
    useOutsideClick(refTargetLanguage, () => setTargetLanguage(false));
    const handleTextToSpeech = async () => {
        if (valueTextTarget.trim() === '') {
            return
        }
        if (isSpeak != '' || languageFrom.code === 'dt') {
            return
        }
        const response = await translateService.textToSpeech(languageFrom.code_speech, valueTextTarget); // URL đến endpoint âm thanh
        if (response.error) {
            return
        }

        const audioBlob = new Blob([response.audioContent], { type: 'audio/mpeg' });
        const url = URL.createObjectURL(audioBlob);
        setIsSpeak(url);
    }
    const handleTextToSpeechTo = async () => {
        if (valueTextTarget.trim() === '') {
            return
        }
        if (isSpeak != '' || languageTo.code === 'dt') {
            return
        }
        const response = await translateService.textToSpeech(languageTo.code_speech, valueTextDetect); // URL đến endpoint âm thanh
        if (response.error) {
            return
        }

        const audioBlob = new Blob([response.audioContent], { type: 'audio/mpeg' });
        const url = URL.createObjectURL(audioBlob);
        setIsSpeak(url);
    }
    const handleClickLanguageTo = (code) => {
        let resultObj = null;
        listLan.map(e => {
            if (e.code === code) {
                resultObj = e;
            }
        })
        setLanguageTo(resultObj);
        setOpenDetect(false);
    }

    const handleClickLanguageFrom = (code) => {
        let resultObj = null;
        listLan.map(e => {
            if (e.code === code) {
                resultObj = e;
            }
        })
        setLanguageFrom(resultObj);
        console.log(resultObj);
        setTargetLanguage(false);
        handleTranslate(resultObj.code);
    }
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(valueTextTarget);
            console.log('Text copied to clipboard');
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };
    const handleClickCopy = () => {
        if (!valueTextTarget) {
            return
        }
        copyToClipboard();
        if (animationClickCopy.current) {
            animationClickCopy.current.style.transform = animationClickCopy.current.style.transform == "rotateX(360deg)" ? "rotateX(0deg)" : "rotateX(360deg)";
            animationClickCopy.current.style.transition = "transform 0.5s ease-in-out";
        }
        setIsCopy(true);
        setTimeout(() => setIsCopy(false), 2000);
    }
    const handleClickedBox = () => {
        setIsClickedBox(true);
    }
    const handleClickedBoxBlur = () => {
        setIsClickedBox(false);
    }
    const handleChange = (event) => {
        setTextDetect(event.target.value);
    };

    const handleClickChange = () => {
        setOpenDetect(prev => !prev);
    };

    const handleClickChangeTarget = () => {
        setTargetLanguage(prev => !prev);
    };
    const handleTranslate = async (fromCode) => {
        setIsLoadingButtonTrans(true);
        if (!refTranslate.current) {
            return
        }
        const content = refTranslate.current.value;
        const result = await translateService.translatText(languageTo.code, fromCode === 'undefined' ? languageFrom.code : fromCode, content);
        setTextTarget(result['success'])
        setIsLoadingButtonTrans(false);
    }
    const handleSwitchLanguage = () => {
        if (languageTo.code === "dt") {
            return
        }
        setIsRotated(pre => !pre);
        if (refSwitch.current) {
            refSwitch.current.style.transform = isRotated ? "rotateY(0deg)" : "rotateY(180deg)";
            refSwitch.current.style.transition = "transform 0.3s ease-in-out";
        }
        const temp = languageTo;
        setLanguageTo(languageFrom);
        setLanguageFrom(temp);
        setTextDetect("");
        setTextTarget("");
    }
    // const startListening = () => SpeechRecognition.startListening({ continuous: true, language:  languageFrom.code_speech});
    const handleClickMic = () => {
        if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
            alert('Browser does not support speech recognition.');
            return;
        }
        if (!browserSupportsSpeechRecognition) {
            alert("Your device not support!!")
            return null
        }
        if (languageTo.code === "dt") {
            alert("You should choose language!!")
            return
        }
        if (!isListenMic) {
            SpeechRecognition.startListening({ continuous: true, language: languageTo.code_speech })
        } else {
            SpeechRecognition.stopListening();
            resetTranscript();
        }
        setIsListenMic(pre => !pre);
    }
    useEffect(() => {
        // Đặt focus khi component được mount
        if (isClickedBox) {
            refTranslate.current.focus();
        }
    }, [isClickedBox]);
    useEffect(() => {
        if (isSpeak) {
            const audio = new Audio(isSpeak);
            audio.play().catch(error => setIsSpeak(''));
            audio.addEventListener('ended', () => {
                setIsSpeak('');
            });
        }
    }, [isSpeak])
    useEffect(() => {
        if (transcript) {
            setTextDetect(transcript);
        }
    }, [transcript]);
    useEffect(() => {
        const handleResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(()=>{
        handleTranslate();
    }, [textTranslate])
    return (
        <Box component="div" sx={{ flexGrow: 1, width: '100%', marginTop: '10px', borderRadius: '5px', minHeight: '500px', backgroundColor: '#ffffff', boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)' }}>
            <Grid container spacing={0}>
                <Grid item xs={6} sx={{ borderBottom: "1px solid #dcdfe4" }}>
                    <ButtonShow title={languageTo.name} open={openDetect} funcClick={handleClickChange} />
                    {openDetect && <ShowLanguage listLans={listLan.filter(e => e.name !== languageFrom.name)} refBox={refOpenDetect} chooseLanguage={handleClickLanguageTo} />}
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', borderBottom: "1px solid #dcdfe4" }}>
                    <Box component={"div"} onClick={handleSwitchLanguage} sx={{ borderRadius: "4px", "&:hover": { backgroundColor: "#f5f7f8", userSelect: "none" } }}>
                        <Box component={"img"} src={IconSwitch} ref={refSwitch} style={{ marginTop: "5px", padding: "2px 8px 2px 8px", width: "25px", userSelect: "none", transformStyle: "preserve-3d", position: "relative" }} ></Box>
                    </Box>
                </Grid>
                <Grid item xs={4} sx={{ borderBottom: "1px solid #dcdfe4" }}>
                    <ButtonShow title={languageFrom.name} open={openTargetLanguage} funcClick={handleClickChangeTarget} />
                    {openTargetLanguage && <ShowLanguage listLans={listLan.filter(e => e.name !== languageTo.name && e.code !== 'dt')} refBox={refTargetLanguage} chooseLanguage={handleClickLanguageFrom} />}
                </Grid>

                <Grid item xs={1} sx={{ borderBottom: "1px solid #dcdfe4", position: "relative" }}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: "-50px",
                            left: '15%',
                            transform: 'translateX(-50%)',
                            marginLeft: "12px",
                            zIndex: "1",
                            transition: "transform 2s ease-in-out",
                            borderRadius: "10px",
                            backgroundColor: '#dcdfe4', // Optional: for better visibility
                            padding: 2, // Optional: adds some padding
                            boxShadow: 0, // Optional: adds a shadow for better visibility
                            visibility: isCopy ? "" : "hidden"
                        }}
                    >
                        <Typography>Copied!</Typography>
                    </Box>
                    <Box sx={{ height: "100%", textAlign: "right", alignContent: "center", width: "100%" }}>
                        <VolumeUpIcon onClick={handleTextToSpeech} sx={{ padding: "5px", marginTop: "8px", marginRight: "8px", borderRadius: "5px", "&:hover": { backgroundColor: "#f5f7f8" } }} />
                        {size.width > 400 ?
                            <ContentCopyIcon ref={animationClickCopy} onClick={handleClickCopy} sx={{ padding: "5px", marginTop: "8px", marginRight: "8px", borderRadius: "5px", "&:hover": { backgroundColor: "#f5f7f8" } }} />
                            : <span></span>}
                    </Box>


                </Grid>
                <Grid item xs={6} sx={{ border: isClickedBox ? "1px solid #0177a9" : "1px solid transparent", borderRadius: "5px" }}>
                    <TextField
                        label=""
                        variant="outlined"
                        inputRef={refTranslate}
                        sx={{
                            minHeight: "400px",
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none',  // Remove the border
                                },
                                '&:hover fieldset': {
                                    border: 'none',  // Remove border on hover
                                },
                                '&.Mui-focused fieldset': {
                                    border: 'none',  // Remove border when focused
                                },
                            },
                            // Remove underline for filled variant (if using filled variant)
                            '& .MuiInput-underline:before': {
                                borderBottom: 'none',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: 'none',
                            },
                        }}
                        placeholder="Type to translate. Please enter the text you need to translate. Maximum 5000 characters"
                        value={valueTextDetect}
                        onChange={handleChange}
                        onFocus={handleClickedBox}
                        onClick={handleClickedBox}
                        onBlur={handleClickedBoxBlur}
                        fullWidth

                        multiline      // Enables multiline input
                        // rows={12}       // Number of rows to display
                        error={valueTextDetect.length > 5000} // Shows error if input length is greater than 5000
                        inputProps={{ maxLength: 5000 }} // Limits input length
                    />
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                        <Box sx={{ display: "flex", }}>
                            {
                                size.width < 400 ? <span></span> :
                                    isListenMic ? <AnimListen onClick={handleClickMic} /> : (<MicIcon onClick={handleClickMic} sx={{

                                        marginLeft: "5px",
                                        padding: "5px",
                                        borderRadius: "20px",
                                        border: isListenMic ? "1px solid #474747" : "1px solid transparent",
                                        '&:hover': {
                                            backgroundColor: "#f5f7f8",
                                        }
                                    }} />)
                            }

                            <VolumeUpIcon onClick={handleTextToSpeechTo} sx={{ padding: "5px", marginTop: "2px", marginRight: "0px", borderRadius: "5px", "&:hover": { backgroundColor: "#f5f7f8" } }} />
                        </Box>

                        <Typography sx={{ width: { xs: "150px" }, display: "flex", marginLeft: "10px", color: "grey", userSelect: "none", fontSize: { xs: "8px", sm: "14px", md: "16px" } }}>
                            <Typography sx={{ fontWeight: "bold", fontSize: { xs: "8px", sm: "14px", md: "16px" } }}>{valueTextDetect.length}</Typography> / 5000
                        </Typography>
                        <LoadingButton loading={isLoadingButtonTrans ? true : false} variant="contained" onClick={() => handleTranslate('undefined')} color="success" sx={{ margin: "2px", fontWeight: "bold", padding: "10px 20px 10px 20px", borderRadius: "20px", fontSize: { xs: "8px", sm: "14px", md: "16px" } }}>
                            Translate
                        </LoadingButton>

                    </Box>

                </Grid>
                <Grid item xs={6}>
                    {/* Add any additional content here */}
                    <Box padding={"16px"}>
                        <Typography
                            variant="outlined"
                            sx={{ width: "100%", lineHeight: "0.2" }}
                            fontSize={"18px"}
                        >
                          
                            {valueTextTarget}
                        </Typography>
                    </Box>

                </Grid>
            </Grid>
        </Box>
    );
}
