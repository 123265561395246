import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import { Height } from '@mui/icons-material';
const styleButtonShow = () => {
    return {
        padding: "12px",
        cursor: "pointer",
        borderRadius:"8px",
        zIndex: 1000,
        userSelect: "none",
        marginTop:"5px",
        marginLeft:"5px",
        display: "inline-flex",
        '&:hover': {
            backgroundColor: '#f5f7f8', // Background color on hover
        },
        justifyContent:"center",
        alignContent:"center",
        display:'flex'
    }
}
export default function ButtonShow({ title, open, funcClick }) {
    return (
        <Box component={"div"} sx={styleButtonShow} onClick={funcClick} >
            <Typography sx={{fontSize: { xs: "10px", sm: "12px", md: "14px" }}}>{title}</Typography>
            {
                open ? (<KeyboardArrowUpIcon sx={{width:{sx:"10px", sm: "10px", md: "14px",lg:"20px",xl:"20px" }}} />):(<KeyboardArrowDownIcon sx={{width:{sx:"10px", sm: "10px", md: "14px",lg:"20px",xl:"20px" }}}/>)
            }
            
        </Box>
    );
};