import * as React from 'react';
import "./style.scss"
export default function AnimListen({onClick}) {
    return (
        <div class="container" onClick={onClick}>
        <div class="BarOne"></div>
        <div class="BarTwo"></div>
        <div class="BarThree"></div>
      </div>
    );
}