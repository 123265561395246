
const ControlsAudio = ({ audioSrc }) => {
    return (
        <div>
            {audioSrc ? (
                <audio controls src={audioSrc} />
            ) : (
                <p>No audio available</p>
            )}
        </div>
    );
};

export default ControlsAudio