import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ImageLogo from "../../public/main-logo.png"
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'transparent',
    // ...theme.typography.body2,

    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.primary,
    boxShadow: "none",
}));

export default function Footer() {
    return (
        <Box component="footer" sx={{ flexGrow: 1, padding: "10px", boxShadow: "none", display: "inline-flex", minHeight: "120px", backgroundColor: "white" }} >
            <Grid container spacing={1} >
                <Grid item xs={2}>
                    <Item>
                        <Box
                            component="img"
                            src={ImageLogo}
                            sx={{
                                marginLeft: '10px',
                                width: {xs:"30px", sm:"50px",md:"100px"},
                            }}
                            alt="Logo"
                        />
                    </Item>
                </Grid>
                <Grid item xs={10}>
                    <Box sx={{alignItems:"center"}}>
                        <Typography variant="body2" color="textSecondary" sx={{fontSize: { xs: "10px", sm: "14px", md: "16px" }}}>
                            Please note that this website's content is translated using an automated translation tool. <br></br>
                            Some nuances and meanings may not be accurately conveyed.
                            We recommend referring to the original content in its native language for precise information.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1,fontSize: { xs: "8px", sm: "14px", md: "16px" } }}>
                            For further details, visit our{' '}
                            <a href="/translation-info" style={{ color: '#1976d2' }}>
                                Translation Information Page
                            </a>.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 ,fontSize: { xs: "10px", sm: "14px", md: "16px" }}}>
                            Contact me to Email{' '}
                            <a href="/translation-info" style={{ color: '#1976d2' }}>
                                hoxuanthinh68@gmail.com
                            </a>
                        </Typography>
                    </Box>

                </Grid>

            </Grid>
        </Box>
    );
};

